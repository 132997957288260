import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep, isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { DATE_FORMAT } from '../FiltersConfig';
import {
  AlFilterModel,
  ComparisonDateFilterModel,
  DateFilterModel,
  LogicalOperatorType,
  OperatorType,
  getUpdatedFiltersValue,
} from '../models/AlFilterModel';
import { FilterKey } from '../types/FilterKey';

interface UseFiltersProps {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

const useFilters = ({ filters, setFilters }: UseFiltersProps) => {
  const { dates, comparisonDates } = useMemo(() => {
    let localDates: Dayjs[] = [];
    let localComparisonDates: Dayjs[] = [];

    filters.forEach((filterModel) => {
      if (filterModel instanceof DateFilterModel) {
        localDates = extractDatesFromFilterModel(filterModel);
      } else if (filterModel instanceof ComparisonDateFilterModel) {
        localComparisonDates = extractDatesFromFilterModel(filterModel);
      }
    });

    return { dates: localDates, comparisonDates: localComparisonDates };
  }, [filters]);

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  function onSetDates(dates: Dayjs[], comparisonDates: Dayjs[]) {
    const newFilters: AlFilterModel[] = [
      new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [dates[0].format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [dates[1].format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
      new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonDates[0].format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonDates[1].format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      }),
    ];

    setFilterValues(newFilters);
  }

  return { dates, comparisonDates, setFilterValues, onSetDates };
};

export default useFilters;

export function extractDatesFromFilterModel(filterModel: AlFilterModel): dayjs.Dayjs[] {
  if (filterModel instanceof DateFilterModel || filterModel instanceof ComparisonDateFilterModel) {
    if (filterModel.conditions) {
      return filterModel.conditions.map((condition) => dayjs(condition.values[0]));
    }
  }

  return [];
}

export function uniqueFiltersByKeys(filters: AlFilterModel[]): AlFilterModel[] {
  const uniqueFilters = new Map<string, AlFilterModel>();

  filters.forEach((filter) => {
    uniqueFilters.set(filter.key, filter); // If a key is duplicated, it will overwrite the previous one
  });

  return Array.from(uniqueFilters.values()); // Convert to an array of FilterModel
}

export function getCurrentEndDateFromFilters(filters: AlFilterModel[]): string | undefined {
  return filters.find((f) => f.key === FilterKey.DATE)?.conditions?.[1]?.values?.[0] as string | undefined;
}

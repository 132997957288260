import { ColumnId } from '@/components/grid/columns/columns.enum';
import { WithFiltersGridContext } from '@/components/grid/types';
import { GridApi } from 'ag-grid-enterprise';
import { useEffect, useMemo } from 'react';
import { AlFilterModel } from '../models/AlFilterModel';
import { getCurrentEndDateFromFilters } from './useFilters';

interface UseFiltersInGridContextProps {
  filters: AlFilterModel[];
  filterDependentColumns: ColumnId[];
  gridApiRef: React.MutableRefObject<GridApi | undefined>;
  gridContextRef: React.MutableRefObject<WithFiltersGridContext | undefined>;
}

const useFiltersInGridContext = ({ filters, filterDependentColumns, gridApiRef, gridContextRef }: UseFiltersInGridContextProps) => {
  // Currently doesn't add all filters to the context, only the needed current end date
  const filterCurrentEndDate = useMemo(() => getCurrentEndDateFromFilters(filters), [filters]);

  useEffect(() => {
    if (!gridContextRef?.current || !filterCurrentEndDate) return;

    gridContextRef.current.filterCurrentEndDate = filterCurrentEndDate;
    gridApiRef.current?.refreshCells({ columns: filterDependentColumns, force: true });
  }, [filterCurrentEndDate]);

  function onGridReadyForFiltersInGridContext() {
    if (!gridContextRef.current) return;
    Object.assign(gridContextRef.current, {
      filterCurrentEndDate: filterCurrentEndDate,
    });
  }

  return { filterCurrentEndDate, onGridReadyForFiltersInGridContext };
};

export default useFiltersInGridContext;

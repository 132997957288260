import Sparkline from '@/components/chart/charts/Sparkline';
import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import { DateValuePair, METRIC_PREVIOUS_AMOUNT_OF_DAYS } from '@/components/metrics/api/metrics-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { Timezone } from '@/modules/users/types/Timezone';
import { ICellRendererParams } from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { FunctionComponent } from 'react';

export interface IAlSparklineCellRendererParams {
  values: number[] | null;
  endDate?: string; // default is last 30d ending with yesterday
  color?: 'green' | 'amber' | 'blue';
  valueFormatter?: (value: number) => string;
}

const POINTS_TO_DISPLAY = METRIC_PREVIOUS_AMOUNT_OF_DAYS;

interface ISparklineCellRendererProps extends ICellRendererParams, IAlSparklineCellRendererParams {}

const SparklineCellRenderer: FunctionComponent<ISparklineCellRendererProps> = ({ values, endDate, color, valueFormatter }) => {
  const { activeProfile } = useActiveTeamContext();

  if (isNil(values) || values.length === 0) return null;

  // if array max value is 0, return null
  if (Math.max(...values) === 0) return null;
  // Optionally we might want to return just a flat line:
  // return (
  //   <div className="h-full w-full flex">
  //     <div className="flex-1 border-b border-b-gray-400 mb-0.5"></div>
  //   </div>
  // );

  const timezone = activeProfile ? activeProfile.timeZone.toString() : Timezone.AmericaLosAngeles.toString();
  const usedEndDate = endDate ? dayjs(endDate).tz(timezone) : dayjs().tz(timezone).subtract(1, 'day');

  const fixedSizeValueArray = adjustArrayWithZeros(values, POINTS_TO_DISPLAY);
  const dataPoints = expandWithDates(fixedSizeValueArray, usedEndDate);

  return <Sparkline data={dataPoints} color={color} valueFormatter={valueFormatter} />;
};

export default SparklineCellRenderer;

function adjustArrayWithZeros(myArray: number[], targetLen: number): number[] {
  const currentLength = myArray.length;

  if (currentLength < targetLen) {
    // Add zeros to the beginning if the array is shorter than targetLen
    const zerosToAdd = targetLen - currentLength;
    myArray.unshift(...Array(zerosToAdd).fill(0));
  } else if (currentLength > targetLen) {
    // Remove elements from the beginning if the array is longer than targetLen
    myArray.splice(0, currentLength - targetLen);
  }

  return myArray;
}

function expandWithDates(olderToRecentValues: number[], endDate: dayjs.Dayjs): DateValuePair[] {
  return olderToRecentValues.map<DateValuePair>((value, index) => {
    // The last value gets the endDate, earlier values get dates going backwards
    const date = endDate.subtract(olderToRecentValues.length - 1 - index, 'day');
    return {
      date: date.format(DATE_FORMAT),
      value: value,
    };
  });
}

import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultProductsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { createProductsWithTimelineQueryKey, productsService } from '../api/products-service';
import ProductsFilterBar from '../components/ProductsFilterBar';
import ProductsMetrics from '../components/ProductsMetrics';
import ProductsSelectionActionsBar from '../components/ProductsSelectionActionsBar';
import ProductsTimelineChart from '../components/ProductsTimelineChart';
import ProductsTable from '../components/products-table/ProductsTable';
import { PRODUCTS_CONTEXT_KEY, ProductsProvider } from '../contexts/ProductsContext';
import { SelectedProduct } from '../models/ProductModel';

let isPaywallDisplayedBefore = false;

const ProductsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({ message: 'Upgrade to Pro to Access the Products Tool Suite' });

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(PRODUCTS_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultProductsFilters());
  });

  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const productsTableGridApiRef = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedProducts([]);
    if (productsTableGridApiRef.current && !productsTableGridApiRef.current.isDestroyed()) {
      productsTableGridApiRef.current.deselectAll();
    }
  }

  const productsWithTimelineQueryKey = createProductsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: productsWithTimeline,
    isLoading: isProductsRowDataLoading,
    isError: isProductsLoadingError,
    error: productsLoadingError,
  } = useQuery({
    queryKey: productsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await productsService.getProductsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading products ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  function onProductsTableGridReady(params: GridReadyEvent) {
    productsTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseProductsPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // // TUTORIAL MODAL
  // const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
  //   UserSettingKey.PRODUCTS_TUTORIAL_DISPLAY_COUNT,
  // );

  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex items-center gap-x-2">
              <div>{t('products_page.header')}</div>
              <div>
                <Tooltip
                  title={
                    'Product advertising data is only for Sponsored Products & Display (Amazon does not provide product-level data for Sponsored Brands)'
                  }
                >
                  <span className="flex items-center font-medium">
                    <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                  </span>
                </Tooltip>
              </div>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <ProductsProvider filters={filters} setFilters={setFilters}>
              <div className="mt-2">
                <AlErrorBoundary>
                  <ProductsMetrics />
                </AlErrorBoundary>
              </div>

              <div className="my-2">
                <AlErrorBoundary>
                  <ProductsTimelineChart />
                </AlErrorBoundary>
              </div>

              <AlErrorBoundary>
                <ProductsFilterBar withTimeline={productsWithTimeline} gridApiRef={productsTableGridApiRef} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <ProductsTable
                  withTimeline={productsWithTimeline}
                  isLoading={isProductsRowDataLoading}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  productsLoadingErrorMessage={productsLoadingError instanceof Error ? productsLoadingError.message : ''}
                  isProductsLoadingError={isProductsLoadingError}
                  onGridReadyCallback={onProductsTableGridReady}
                  noTopBorderRadius={true}
                  filters={filters}
                />
              </AlErrorBoundary>
            </ProductsProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <ProductsSelectionActionsBar
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          totalProductsCount={productsWithTimeline?.products ? productsWithTimeline.products.length : 0}
          onDiscardClicked={clearSelections}
          productsTableGridApiRef={productsTableGridApiRef}
        />
      </AlErrorBoundary>

      {/* <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={''}
        title={'How to Use the Search Terms Tab'}
      /> */}

      {paywallModalElement}
    </>
  );
};

export default ProductsPage;
